$(document).ready(function() {
  let timer = null;

  function searchLive(query) {
    timer = null;
    $.get("/search/live/?query=" + query, function(data) {
      $("#search-live-results").html(data);
    });
  }

  $("#search-query").on("input", function() {
    const query = $(this).val();
    if (query.length > 0) {
      $("#search-live-results").show();
    } else {
      $("#search-live-results").hide();
    }
    $("#search-live-results").html(
      `<div class="list-group-item list-group-item-dark p-2"><small>Searching for ${query}...</small></div>`
    );
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(function() {
      searchLive(query);
    }, 1000);
  });
});
